<template>
  <div class="all">

    <infoHeader :content_name="'权限详情'" />

    <el-button type="primary" class="save" @click="save()">保存权限更改</el-button>

    <el-form ref="form" label-width="160px" :label-position="'left'">
      <el-form-item label="权限名">
        <el-input v-model="Form.roles_name"></el-input>
      </el-form-item>

      <el-form-item label="权限组说明">
        <el-input type="textarea" v-model="Form.roles_info"></el-input>
      </el-form-item>
    </el-form>

    <el-divider></el-divider>

    <el-table
      :data="tableData"
      border
      max-height="600"
    >
      <el-table-column prop="id" label="ID" width="180">
        <template slot-scope="scope">
          <div class="table-item">
            <h4 class="left-table-item">{{ scope.row.module_name }}</h4>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="roles_info" label="姓名">
        <template slot-scope="scope">
          <span
            class="checkBox-item"
            v-for="(value, item) in scope.row.resources"
            :key="item"
          >
            <el-checkbox v-model="value.status">{{ value.resources_name }}</el-checkbox>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { personnelAccount_JurisdictionGroupUpdate, personnelAccount_JurisdictionGroupGet } from "@/api/manage/personnel_account_jurisdiction.js"
export default {
  components: {},
  props: [],
  data() {
    return {
      showHeader:false,
      tableData: [],
      Form: {
        roles_name:'',
        roles_info:''
      },
      query: {
        "group_id":this.$route.params.group_id
      }
    };
  },
  created() {
    //权限组数据
    personnelAccount_JurisdictionGroupGet(this.query).then((res)=>{
      this.tableData=res.data.tabledata,
      this.Form.roles_name = res.data.roles_name
      this.Form.roles_info = res.data.roles_info
    })
  },
  watch: {
    result: function () {
      console.log("变化了：", this.result);
    },
  },
  methods: {
    //发送更新数据
    save() {
      var dataOutput = {}
      dataOutput["group_id"]=this.$route.params.group_id
      //参数拼接
      var tempData=this.tableData
        var tempjurisdiction=[]
        tempData.forEach(element => {
            element.resources.forEach((elementItem)=>{
              if(elementItem.status==true){
                tempjurisdiction.push({"module_id":element.module_id,"resources_id":elementItem.resources_id})
              }
            })
        });
      dataOutput["jurisdiction"]=tempjurisdiction;
      dataOutput["roles_name"]=this.Form.roles_name
      dataOutput["roles_info"]=this.Form.roles_info
      personnelAccount_JurisdictionGroupUpdate(dataOutput).then((res)=>{
         console.log(res)
        if(res.code==200){
          this.$message.success("更改成功");
          // this.$router.push({name:"permissionGroupIndex"})
        }else{
          this.$message.info(res.msg);
        }
      })
    },
    toggleSwitch() {
      console.log(this.tableData);
    },
    //合并行
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      row, column, rowIndex, columnIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
// .all {
//   transform-origin: 0px 0px !important; //在使用transform方法进行文字或图像的变形时，是以元素的中心点为基准点进行的。使用transform-origin属性，可以改变变形的基准点,用法：transform-origin: 10px 10px;
//   transform: scale(
//     0.8,
//     0.8
//   ) !important; //第一个参数:指定水平方向的缩放倍率，第二个参数:指定垂直方向的缩放倍率
// }
.box-card {
  margin: 12px;
}
.save {
  float: right;
  margin-top: -65px;
}
.table-item {
  position: absolute !important;
  margin-top: -12px !important;
  margin-left: 20px;
  width: 110px;
}
.commonTable {
}
.right-item {
  float: right;
  margin-right: 650px;
}
.left-item {
  float: left;
  margin-top: 20px;
  margin-left: 10px;
  width: 300px;
}
.right-table-item {
  //   position: relative;
  margin-top: 1px;
  float: right;
}
.left-table-item {
  //  position: relative;
  margin-top: 3px;
  //   margin-left:45px ;

  float: left;
}
.checkBox-item {
  margin: 30px;
}
</style>
